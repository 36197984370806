<template>
  <div class="minha-conta">
    <header class="bg-dark py-2">
      <div class="container">
        <div class="row">
          <div class="col">
            <router-link to="/">
              <img src="/img/logo.svg" width="150" height="34" />
            </router-link>
            <span class="badge badge-success ml-2">admin</span>
          </div>
          <div class="col text-right">
            <SideNav />
          </div>
        </div>
      </div>
    </header>
    <div class="bg-gradient">
      <div class="container text-center pt-3">
        <h3>Baixa de Pagamento</h3>
        <div class="h-line" style="width:142px;"></div>
      </div>
    </div>

    <div v-if="loading" class="text-center">
      <lottie-player
        src="/anim/mountain_bike.json"
        background="transparent"
        speed="1.7"
        style="width: 72px; height: 72px; margin:0 auto;"
        autoplay
        loop
        class="mt-3"
      ></lottie-player>
      <div>Carregando, por favor aguarde...</div>
    </div>
    <div v-if="!loading" class="container mt-5 pb-5">
      <div class="row ">
        <div class="col-lg-12 order-lg-2">
          <div v-if="habilitar">
          <button
            type="submit"
            class="btn btn-success mb-3"
            @click.prevent="exibirModalBaixaPagamento"
            :disabled="loadingBaixa "
          >
            <span v-if="!loadingBaixa">Apurar seguros</span>
            <span v-if="loadingBaixa">Por favor aguarde...</span>
          </button></div>
          <div>
            <table class="table text-center">
              <thead>
                <tr>
                  <th>Data da baixa</th>
                  <th>Quantidade</th>
                  <th>Valor total</th>
                  <th>Download</th>
                  <th>Transferência</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="lstItens.length === 0">
                  <div class="mt-3">Nenhuma baixa encontrada</div>
                </tr>
                <tr v-for="(item, index) in lstItens" :key="index">
                  <td>
                    {{ formatacaoData.formatarDataHoraLocal(item.criado) }}
                  </td>
                  <td>{{ item.quantidadeApurada }}</td>
                  <td>{{ formatacaoMoeda(item.valorApurado) }}</td>
                  <td>
                    <a
                      target="_blank"
                      v-if="item.urlExcel"
                      :href="item.urlExcel"
                      class="mr-2"
                    >
                      <i class="fas fa-file-alt mr-1"></i>Excel
                    </a>
                    <span v-else>Sem Excel </span>
                    <span> | </span>
                    <a target="_blank" v-if="item.urlCnab" :href="item.urlCnab"
                      ><i class="fas fa-file-alt mr-1"></i>CNAB
                    </a>
                    <span v-else> Sem CNAB</span>
                  </td>
                  <td>
                    <button
                      v-if="!item.dataTransferenciaBancaria"
                      class="btn btn-link"
                      :disabled="loadingSalvar"
                      @click.prevent="onclickAtualizarLog(item.id)"
                    >
                      <span v-if="!loadingSalvar"
                        >Confirmar transferência realizada</span
                      >
                      <span v-if="loadingSalvar">
                        <small class="text-white">Salvando</small>
                      </span>
                    </button>
                    <span v-else>
                      <b>Realizada em:</b>
                      {{
                        formatacaoData.formatarDataHoraLocal(
                          item.dataTransferenciaBancaria
                        )
                      }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <ModalBaixaPagamento
      :showModal="showModalBaixa"
      @hide="hideModalBaixaPagamento"
    />
  </div>
</template>

<script>
import SideNav from "../../components/Admin/SideNav";
import ModalBaixaPagamento from "../../components/Admin/ModalBaixaPagamento";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../config";
import captureError from "../../helpers/captureError";
import formatacaoMoeda from "../../helpers/formatacaoMoeda";
import formatacaoData from "../../helpers/formatacaoData";

export default {
  components: {
    ModalBaixaPagamento,
    SideNav,
  },
  data() {
    return {
      config,
      formatacaoMoeda,
      formatacaoData,
      idCliente: undefined,
      loadingBaixa: false,
      loadingGerar: false,
      lstItens: [],
      showModalBaixa: false,
      loadingSalvar: false,
      loading: false,
      habilitar: false,
    };
  },
  created() {
    var usuario = JSON.parse(localStorage.getItem("UserInfo"));
    if (
      usuario &&
      (usuario.login === "samarone" ||
        usuario.login === "team" ||
        usuario.login === "devteam")
    ) {
      this.habilitar=true;
    }
    this.popularBaixa();
  },
  beforeRouteEnter(to, from, next) {
    document.body.classList.add("hide-external-buttons");
    next();
  },
  beforeRouteLeave(to, from, next) {
    document.body.classList.remove("hide-external-buttons");
    next();
  },
  methods: {
    exibirModalBaixaPagamento() {
      this.showModalBaixa = true;
    },
    hideModalBaixaPagamento(status) {
      this.showModalBaixa = false;
      if (status.modificou) {
        this.popularBaixa();
      }
    },
    async popularBaixa() {
      try {
        this.loading = true;
        //TODO: migrar para a v2 só quando a essor disponibilizar os ambientes de homologação e de produção(testar fluxo completo);
        var response = await axios.get(
          `${config.API_URLV2}/logBaixaPagamento/BuscarLogBaixaPagamento`
        );
        this.lstItens = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        captureError(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível consultar seu saldo. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
    async onclickAtualizarLog(idLogBaixa) {
      var result = await Swal.fire({
        title: "Tem certeja que deseja confirmar a transferência?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });
      if (result?.isConfirmed == false || result?.dismiss == "cancel") {
        return;
      }

      try {
        this.loadingSalvar = true;
        //TODO: migrar para a v2 só quando a essor disponibilizar os ambientes de homologação e de produção(testar fluxo completo);
        await axios.put(`${config.API_URLV2}/logBaixaPagamento/${idLogBaixa}`);

        this.popularBaixa();
      } catch (error) {
        this.loadingSalvar = false;

        captureError(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível atualizar a data de transferência. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
};
</script>

<style scoped>
.minha-conta {
  height: 100%;
  /* background-color: #f7f7f7; */
}

.bg-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.14609593837535018) 0%,
    rgba(255, 255, 255, 0) 70%
  );
}

.icone-principal {
  font-size: 43px;
  color: #28a745;
  background: -webkit-linear-gradient(#76b566, #28a745);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
