<template>
  <b-modal
    :visible="showModal"
    title="Baixa Pagamento"
    :hide-footer="true"
    @hide="hideModal"
    ><div v-if="loadingBuscar">
      <div class="text-center">
        <lottie-player
          src="/anim/mountain_bike.json"
          background="transparent"
          speed="1.7"
          style="width: 72px; height: 72px; margin:0 auto;"
          autoplay
          loop
          class="mt-3"
        ></lottie-player>
        <div>Carregando, por favor aguarde...</div>
      </div>
    </div>
    <div v-else>
      <b>Seguros apurados</b>

      <form class="mt-2">
        <div class="row">
          <div class=" mb-3 col-md-6  form-group">
            <label for="firstName">Quantidade:</label>
            <h5>{{ lstItens.qtdTotal }}</h5>
          </div>

          <div class=" mb-3 col-md-6  form-group">
            <label>Valor total:</label>
            <h5>
              {{ formatacaoMoeda(lstItens.valorTotalSemAssistencia) }}
            </h5>
          </div>
        </div>
        <div class="row ml-1 mr-1">
          <b-button
            class="col-md-6 btn-sm "
            variant="success"
            @click.prevent="realizarBaixa"
            :disabled="loadingEnviar || lstItens.qtdTotal == 0"
          >
            <span v-if="!loadingEnviar">Enviar agora</span>
            <span v-if="loadingEnviar">
              <small class="text-white">Enviando...</small>
            </span>
          </b-button>
          <b-button class=" btn-sm  col-md-5 ml-1" @click.prevent="hideModal()">
            <span>Não enviar</span>
          </b-button>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

import config from "../../config";
import captureError from "../../helpers/captureError";
import formatacaoMoeda from "../../helpers/formatacaoMoeda";

export default {
  props: ["showModal"],
  data() {
    return {
      config,
      formatacaoMoeda,
      loadingEnviar: false,
      loadingBuscar: false,
      modificou: false,
      lstItens: {},
    };
  },
  watch: {
    showModal: function(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.verificarBaixa();
      }
    },
  },
  methods: {
    hideModal(arg, data) {
      this.$emit("hide", data || { modificou: false });
      this.lstItens = {};
    },
    async verificarBaixa() {
      try {
        this.loadingBuscar = true;
        var response = await axios.get(
          `${config.API_URLV2}/cobrancaseguro/contabilizarRepasseEssor`
        );
        this.lstItens = response.data;
        this.loadingBuscar = false;
      } catch (e) {
        captureError(e);
        this.loadingBuscar = false;

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível a busca da baixa. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },

    async realizarBaixa() {
      try {
        this.loadingEnviar = true;
  //TODO: migrar para a v2 só quando a essor disponibilizar os ambientes de homologação e de produção(testar fluxo completo);
   
        await axios.post(
          `${config.API_URLV2}/cobrancaSeguro/BaixaPagamentoEssor`
        );

        Swal.fire("Baixa efetuada com sucesso!", "", "success");

        this.loadingEnviar = false;
        this.hideModal(undefined, { modificou: true });
      } catch (e) {
        captureError(e);
        this.loadingEnviar = false;

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível efetuar a baixa. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
};
</script>
